import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import { useEffect, useState } from "react";
import "./testimonial.css";
import prev from "../../images/testimonials/navprev.svg";
import next from "../../images/testimonials/navnext.svg";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Loader from "../loader";
import ReactHtmlParser from "react-html-parser";
import { testimonial_api } from "./api";

const Testimonials = () => {
  const [testimonial, setTestimonial] = useState([]);
  const [loading, setLoading] = useState(false);

  var options = {
    margin: -18,
    responsiveClass: true,
    nav: testimonial?.length >= 2 ? true : false,
    navText: [
      testimonial?.length >= 2
        ? `<img src = ${prev} id="next-btn" alt = "navprev">`
        : null,
      testimonial?.length >= 2
        ? `<img src = ${next}  id = "prev-btn" alt = "navnext">`
        : null,
    ],
    dots: testimonial?.length >= 2 ? true : false,
    autoplay: false,
    loop: testimonial?.length >= 2 ? true : false,
    center: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        margin: 10,
      },
      400: {
        items: 1,
        margin: 10,
      },
      600: {
        items: 1,
        margin: 10,
      },
      700: {
        items: 1,
        margin: 10,
      },
      993: {
        items: 3,
        margin: -16,
      },
      1201: {
        items: 3,
      },
    },
  };

  // Use Effect Section
  useEffect(() => {
    getTestimonial();
  }, []);
  useEffect(() => {
    // handleTestmonialArrowWidth();
  }, []);

  //  End of Use Effect Section

  // User Define Function Section

  const getTestimonial = async () => {
    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    setLoading(true);
    testimonial_api?.getTestimonial((err, data) => {
      console.log(err);
      console.log(data?.data);
      if (err == null) {
        console.log(data?.data);
        const testimonialsdata = data?.data?.data?.map((item) => {
          const fulldescription = ReactHtmlParser(item?.content)?.[0]?.props
            ?.children?.[1];
          return {
            ...item,
            image:
              item?.image !== null && item?.image.includes("https")
                ? item?.image
                : item?.image == null
                ? "https://cms.navigatecommodities.com/storage/images/xdvg6rue7G1OoxopAsTTG2W6Y1y54hsDSBsCTRFP.png"
                : `${imageUrl}${item?.image}`,
            content: item?.content,
            description:
              fulldescription?.split(" ")?.length > 50
                ? fulldescription?.slice(0, 100) + "..."
                : fulldescription,
          };
        });
        setTestimonial(testimonialsdata);
      } else {
        setTestimonial([]);
      }
      setLoading(false);
    });
  };
  //   End of User Define Function
  function handleTestmonialArrowWidth() {
    let target = document.querySelector(".owl-nav");
    let dots = document.querySelector(".owl-dots");
    const firstChild = target.children[0];
    const secondChild = target.children[1];
    let prev = document.querySelector(".owl-nav .owl-prev #prev-btn");
    let next = document.querySelector(".owl-nav .owl-next #next-btn");
    // target = target == undefined ? undefined : target[0]
    // dots = dots == undefined ? undefined : dots[0]
    console.log("target", target);
    console.log(firstChild);
    if (target != undefined) {
      target.classList.remove("disabled");
      // alert(dots.offsetWidth)
      target.removeChild(firstChild);
      let widthdots = dots.offsetLeft;
      // firstChild.style.width = `400px`
      // firstChild.style.background="red"
      // target.appendChild(firstChild)
    }
  }
  console.log("testimonial", testimonial?.length);
  return (
    <section className="testimonial">
      <Container>
        <h1 className="text-center">Testimonials</h1>
        <Row>
          <Col className={`${loading == true ? "loader-section" : ""}`} xs={12}>
            {loading == true ? (
              <Loader />
            ) : (
              <OwlCarousel className="slider-items owl-carousel" {...options}>
                {testimonial?.map((item) => {
                  return (
                    <div className="testimonial-card item">
                      <div className="user-img">
                        <img src={item?.image} alt="user" />
                      </div>
                      <div className="testimonial-content">
                        <div
                          dangerouslySetInnerHTML={{ __html: item?.content }}
                        />
                      </div>
                      <div className="user-name text-center">
                        <h3>{item?.name}</h3>
                        <div>{item?.designation}</div>
                        <p className="company">{item?.company}</p>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
