import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";
import "./client-carousel.css";
import Marquee from "react-fast-marquee";
import Arcelor_logo from "../../images/new-client-logo/21-2.png";
import Ddj_logo from "../../images/new-client-logo/22-2.png";
import Gold_man from "../../images/new-client-logo/6-2.png";
import SGX_logo from "../../images/new-client-logo/12-2.png";
import Anglo_logo from "../../images/new-client-logo/18-2.png";
import CELSA_group from "../../images/new-client-logo/2-2.png";
import UOB_logo from "../../images/new-client-logo/15-2.png";
import DW_logo from "../../images/new-client-logo/23-2.png";
import emr_logo from "../../images/new-client-logo/4-2.png";
import Engelhart_logo from "../../images/new-client-logo/5-2.png";
import gsmt_logo from "../../images/new-client-logo/7-2.png";
import Ferrexpo_logo from "../../images/new-client-logo/24-2.png";
import Megasa_logo from "../../images/new-client-logo/9-2.png";
import Mitsui_logo from "../../images/new-client-logo/10-2.png";
import LME_logo from "../../images/new-client-logo/25-2.png";
import Rimeco_logo from "../../images/new-client-logo/11-2.png";
import Stena_logo from "../../images/new-client-logo/14-2.png";
import Millenium_logo from "../../images/new-client-logo/26-2.png";
import WARD_logo from "../../images/new-client-logo/16-2.png";
import Atlas_logo from "../../images/new-client-logo/19-2.png";
import Orion_logo from "../../images/new-client-logo/27-2.png";
import RWE_logo from "../../images/new-client-logo/28-2.png";
import SNorton_logo from "../../images/new-client-logo/29-2.png";
import Stemcor_logo from "../../images/new-client-logo/30-2.png";

import HksLogo from "../../images/new-client-logo/HKS-logo.svg";
import HksLogoGrey from "../../images/new-client-logo/HKS-logo-Greyscale.svg";
import TsrLogo from "../../images/new-client-logo/TSR-Recycling-Logo.png";
import TsrLogoGrey from "../../images/new-client-logo/TSR-Recycling-Logo-Greyscale-new.png";

import ekinciler from "../../images/new-client-logo/ekinciler.png";
import ekincilerGrey from "../../images/new-client-logo/ekinciler-greyscale.png";

import ekincilerOld from "../../images/new-client-logo/ekinciler.jpg";
import ekincilerGreyOld from "../../images/new-client-logo/ekinciler-greyscale.jpg";

import Noksel from "../../images/new-client-logo/Noksel.jpg";
import NokselGrey from "../../images/new-client-logo/Noksel-Greyscale.jpg";

import Arcelor_logo1 from "../../images/new-client-logo/21-1.png";
import Ddj_logo1 from "../../images/new-client-logo/22-1.png";
import Gold_man1 from "../../images/new-client-logo/6-1.png";
import SGX_logo1 from "../../images/new-client-logo/12-1.png";
import Anglo_logo1 from "../../images/new-client-logo/18-1.png";
import CELSA_group1 from "../../images/new-client-logo/2-1.png";
import UOB_logo1 from "../../images/new-client-logo/15-1.png";
import DW_logo1 from "../../images/new-client-logo/23-1.png";
import emr_logo1 from "../../images/new-client-logo/4-1.png";
import Engelhart_logo1 from "../../images/new-client-logo/5-1.png";
import gsmt_logo1 from "../../images/new-client-logo/7-1.png";
import Ferrexpo_logo1 from "../../images/new-client-logo/24-1.png";
import Megasa_logo1 from "../../images/new-client-logo/9-1.png";
import Mitsui_logo1 from "../../images/new-client-logo/10-1.png";
import LME_logo1 from "../../images/new-client-logo/25-1.png";
import Rimeco_logo1 from "../../images/new-client-logo/11-1.png";
import Stena_logo1 from "../../images/new-client-logo/14-1.png";
import Millenium_logo1 from "../../images/new-client-logo/26-1.png";
import WARD_logo1 from "../../images/new-client-logo/16-1.png";
import Atlas_logo1 from "../../images/new-client-logo/19-1.png";
import Orion_logo1 from "../../images/new-client-logo/27-1.png";
import RWE_logo1 from "../../images/new-client-logo/28-1.png";
import SNorton_logo1 from "../../images/new-client-logo/29-1.png";
import Stemcor_logo1 from "../../images/new-client-logo/30-1.png";

import Cronimet_logo from "../../images/new-client-logo/cronimet1.png";
import Cronimet_logo1 from "../../images/new-client-logo/Cronimet.png";

import Enicor_logo from "../../images/new-client-logo/enicor1.png";
import Enicor_logo1 from "../../images/new-client-logo/enicor.jpg";

import London_logo from "../../images/new-client-logo/london1.png";
import London_logo1 from "../../images/new-client-logo/london.jpg";

import Oryx_logo from "../../images/new-client-logo/oryx1.png";
import Oryx_logo1 from "../../images/new-client-logo/oryx.png";

import Rio_logo from "../../images/new-client-logo/rio1.png";
import Rio_logo1 from "../../images/new-client-logo/rio.png";

import Riva_logo from "../../images/new-client-logo/riva1.png";
import Riva_logo1 from "../../images/new-client-logo/Riva.png";

import hjhanLogo from "../../images/new-client-logo/hjhan.png";
import hjhanLogoFaded from "../../images/new-client-logo/hjhan-fade.png";

import { useState } from "react";

const TrustedClient = () => {
  const [companylogos, setCompanyLogo] = useState([
    {
      primary: Arcelor_logo,
      secondary: Arcelor_logo1,
      hover: false,
    },
    {
      primary: Anglo_logo,
      secondary: Anglo_logo1,
      hover: false,
    },
    // {
    //   primary: Atlas_logo,
    //   secondary: Atlas_logo1,
    //   hover: false,
    // },
    {
      primary: CELSA_group,
      secondary: CELSA_group1,
      hover: false,
    },
    {
      primary: Cronimet_logo,
      secondary: Cronimet_logo1,
      hover: false,
    },
    {
      primary: Ddj_logo,
      secondary: Ddj_logo1,
      hover: false,
    },
    // {
    //   primary: DW_logo,
    //   secondary: DW_logo1,
    //   hover: false,
    // },
    {
      primary: emr_logo,
      secondary: emr_logo1,
      hover: false,
    },
    {
      primary: Enicor_logo,
      secondary: Enicor_logo1,
      hover: false,
    },
    // {
    //   primary: Engelhart_logo,
    //   secondary: Engelhart_logo1,
    //   hover: false,
    // },
    {
      primary: ekincilerGrey,
      secondary: ekinciler,
      hover: false,
    },
    {
      primary: Ferrexpo_logo,
      secondary: Ferrexpo_logo1,
      hover: false,
    },
    {
      primary: Gold_man,
      secondary: Gold_man1,
      hover: false,
    },
    {
      primary: gsmt_logo,
      secondary: gsmt_logo1,
      hover: false,
    },
    {
      primary: hjhanLogoFaded,
      secondary: hjhanLogo,
      hover: false,
    },
    {
      primary: HksLogoGrey,
      secondary: HksLogo,
      hover: false,
    },
    {
      primary: LME_logo,
      secondary: LME_logo1,
      hover: false,
    },
    // new logo
    {
      primary: London_logo,
      secondary: London_logo1,
      hover: false,
    },
    {
      primary: Millenium_logo,
      secondary: Millenium_logo1,
      hover: false,
    },
    {
      primary: Megasa_logo,
      secondary: Megasa_logo1,
      hover: false,
    },
    {
      primary: Mitsui_logo,
      secondary: Mitsui_logo1,
      hover: false,
    },
    {
      primary: NokselGrey,
      secondary: Noksel,
      hover: false,
    },
    {
      primary: Orion_logo,
      secondary: Orion_logo1,
      hover: false,
    },
    {
      primary: Oryx_logo,
      secondary: Oryx_logo1,
      hover: false,
    },
    {
      primary: RWE_logo,
      secondary: RWE_logo1,
      hover: false,
    },
    {
      primary: Rimeco_logo,
      secondary: Rimeco_logo1,
      hover: false,
    },
    {
      primary: Rio_logo,
      secondary: Rio_logo1,
      hover: false,
    },
    {
      primary: Riva_logo,
      secondary: Riva_logo1,
      hover: false,
    },
    {
      primary: SNorton_logo,
      secondary: SNorton_logo1,
      hover: false,
    },
    {
      primary: SGX_logo,
      secondary: SGX_logo1,
      hover: false,
    },
    // {
    //   primary: Stemcor_logo,
    //   secondary: Stemcor_logo1,
    //   hover: false,
    // },
    // {
    //   primary: Stena_logo,
    //   secondary: Stena_logo1,
    //   hover: false,
    // },
    {
      primary: TsrLogoGrey,
      secondary: TsrLogo,
      hover: false,
    },
    {
      primary: UOB_logo,
      secondary: UOB_logo1,
      hover: false,
    },
    {
      primary: WARD_logo,
      secondary: WARD_logo1,
      hover: false,
    },
  ]);

  const onHover = (index) => {
    const main_image = document?.getElementById(`image${index}`);
    const secondryImage = document?.getElementById(`secimage${index}`);

    main_image.classList.add("main_image");
    secondryImage.classList.remove("hide-secondry");
  };
  const onOut = (index) => {
    const main_image = document?.getElementById(`image${index}`);
    const secondryImage = document?.getElementById(`secimage${index}`);

    main_image.classList.remove("main_image");
    secondryImage.classList.add("hide-secondry");
  };
  return (
    <section className="trusted-client">
      <Container>
        <h1 className="text-center">Trusted By</h1>
        <Row>
          <Col xs={12}>
            <Marquee pauseOnHover={true} speed={30} className="trusted-client">
              {companylogos?.map((item, i) => {
                return (
                  <div
                    className="logo-img"
                    onMouseEnter={() => {
                      onHover(i);
                    }}
                    onMouseLeave={() => {
                      onOut(i);
                    }}
                  >
                    {" "}
                    <img
                      onMouseEnter={(e) => {
                        e.target.src = item?.secondary;
                      }}
                      onMouseLeave={(e) => {
                        e.target.src = item?.primary;
                      }}
                      src={item?.primary}
                      id={`image${i}`}
                      alt="client-logo"
                    />
                    <img
                      src={item?.secondary}
                      id={`secimage${i}`}
                      className="hide-secondry"
                      alt="client-logo"
                    />
                  </div>
                );
              })}
            </Marquee>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TrustedClient;
